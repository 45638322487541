<!--
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: index.vue
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:02
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:39:55
 Modificado por: pedro.rosa
-->
<template>
  <v-card
    class="pt-6 mx-auto"
    flat
    min-width="100%"
    :loading="loadingConfig"
    :disabled="loadingConfig"
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-col cols="6">
        <v-card-text>
          <div>
            <v-form>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="nomeFiltro"
                    :label="this.$t('ConfigTelas.nomeFiltro')"
                    :placeholder="this.$t('ConfigTelas.nomeFiltro')"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="iconeFiltro"
                    :label="this.$t('ConfigTelas.iconFiltro')"
                    :placeholder="this.$t('ConfigTelas.escolhaIcon')"
                    :items="iconesFiltro"
                    outlined
                    dense
                  >
                    <template #[`item`]="{ item, on, attrs }">
                      <v-list>
                        <v-list-item-group>
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-icon>
                              <v-icon>
                                {{ item }}
                              </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="sugestaoSelecionada"
                    label="Sugestões"
                    :placeholder="this.$t('ConfigTelas.escolhaFiltro')"
                    :items="sugestoes"
                    outlined
                    dense
                  />
                </v-col>
                <!-- <v-col cols="4">
                <v-card-text>
                        Cor do filtro
                        <v-menu
                          transition="slide-x-transition"
                          bottom
                          right
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>
                                add
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-color-picker
                            v-model="corFiltro"
                            show-swatches
                          />
                        </v-menu>
                      </v-card-text>
                    </v-col> -->
              </v-row>
            </v-form>
          </div>
        </v-card-text>
        <div class="focusFiltro">
          <v-card
            width="100%"
            height="120"
            color="white"
            :style="bb"
            class="promo-drawer__content"
          >
            <v-row class="rowCentered">
              <v-icon
                color="#666"
                size="48"
              >
                {{ iconeFiltro }}
              </v-icon>
            </v-row>
            <v-row class="rowCentered">
              <span class="ofertas">{{ nomeFiltro }}</span>
            </v-row>
          </v-card>
        </div>
        <v-container>
          <v-btn
            v-if="!editandoFiltro"
            text
            color="green"
            @click="adicionarFiltro"
          >
            <v-icon :style="{ marginRight: '10px' }">
              add
            </v-icon>
            {{ this.$t("ConfigTelas.adicionaFiltro") }}
          </v-btn>
          <div v-else>
            <v-btn
              text
              @click="alterarFiltro"
            >
              <v-icon> edit </v-icon>
              {{ this.$t("ConfigTelas.alteraFiltro") }}
            </v-btn>
            <v-btn
              text
              color="error"
              style="margin-left: 12px"
              @click="desativarEdicao"
            >
              <v-icon> close </v-icon>
              {{ this.$t("ConfigTelas.cancelar") }}
            </v-btn>
          </div>
        </v-container>
      </v-col>
      <v-divider vertical />
      <v-col
        cols="5"
        :style="{ marginLeft: '10px', overflowY: 'auto' }"
      >
        <div class="switchBoxes">
          <v-list
            two-line
            max-height="40vh"
          >
            <v-list-item-group active-class="orange lighten-5">
              <div
                v-for="(item, index) in filtros"
                :key="item.tc010_nr_filtro"
              >
                <v-list-item @click="editarFiltro(item)">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.tc010_ds_filtro" />
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="item.tc010_cd_situac === 1 ? 'Ativo' : 'Inativo'"
                    />
                    <v-switch
                      v-model="item.active"
                      inset
                      @change="atualizaStatusFiltro(item)"
                    />
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="index < filtros.length - 1"
                  :key="index"
                />
              </div>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <v-card-actions>
      <v-row
        align="center"
        justify="end"
        no-gutters
      >
        <v-btn
          depressed
          color="primary"
          @click="salvarFiltros"
        >
          Salvar
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      loadingConfig: true,
      params: undefined,

      filtros: [],

      filtroAtivo: true,

      meuFiltro: "",
      filtroSelecionado: null,

      ativarFiltros: false,
      novoFiltro: false,
      iconesFiltro: [
        "123",
        "3d_rotation",
        "abc",
        "accessibility",
        "accessibility_new",
        "accessible",
        "accessible_forward",
        "account_balance",
        "account_balance_wallet",
        "account_box",
        "account_circle",
        "add_card",
        "add_shopping_cart",
        "add_task",
        "add_to_drive",
        "addchart",
        "admin_panel_settings",
        "ads_click",
        "alarm",
        "alarm_add",
        "alarm_off",
        "alarm_on",
        "all_inbox",
        "all_out",
        "analytics",
        "anchor",
        "android",
        "announcement",
        "api",
        "app_blocking",
        "app_shortcut",
        "arrow_circle_down",
        "arrow_circle_left",
        "arrow_circle_right",
        "arrow_circle_up",
        "arrow_right_alt",
        "article",
        "aspect_ratio",
        "assessment",
        "assignment",
        "assignment_ind",
        "assignment_late",
        "assignment_return",
        "assignment_returned",
        "assignment_turned_in",
        "assured_workload",
        "autorenew",
        "home",
        "flash_on",
        "sell",
        "account_circle",
        "lock",
        "thumb_up",
        "visibility",
        "delete",
        "surfing",
      ],
      sugestaoSelecionada: "",
      filtrosSugeridos: [
        {
          tc010_ds_filtro: this.$t("ConfigTelas.ofertaDia"),
          tc010_im_filtro: "sell",
        },
        {
          tc010_ds_filtro: this.$t("ConfigTelas.ofertaRapida"),
          tc010_im_filtro: "flash_on",
        },
      ],

      editandoFiltro: false,
      corFiltro: "#f49636",
      bordaInferior: "",
      nomeFiltro: "",
      iconeFiltro: "thumb_up",
      bb: [
        { padding: "15px 0" },
        { borderBottom: "5px solid #f49636 !important" },
      ],
    };
  },
  computed: {
    ...mapState([
      "configSaved",
      "novoFiltroRapido",
      "arrayFiltrosRapidos",
      "objFiltrosRapidos",
      "primeiraConfig",
      "tc000",
      "tc010",
    ]),
    sugestoes() {
      return this.filtrosSugeridos.map((filtro) => filtro.tc010_ds_filtro);
    },
    /*     meusFiltros() {
      return this.filtros.map(filtro => {
        if (filtro.tc010_cd_situac === 1) {
          return {
            text: filtro.tc010_ds_filtro,
            value: {
              ...filtro,
            },
          }
        }
      })
    }, */
  },
  watch: {
    configSaved() {
      this.carregaFiltros();
      this.vuexSet({ value: false, stateName: "configSaved" });
    },
    /*     meuFiltro() {
      const filtro = this.meuFiltro
      this.vuexSet({ value: filtro, stateName: 'objFiltrosRapidos' })
    }, */
    ativarFiltros() {
      this.vuexSet({ value: this.ativarFiltros, stateName: "ativarFiltros" });
    },
    /* Muda a cor da borda do filtro */
    corFiltro: function () {
      this.bb[1].borderBottom = `5px solid #f49636 !important`;
    },
    /* Recebe o filtro clicado na pré-visualização (tela da direita) */
    objFiltrosRapidos: {
      handler() {
        if (this.objFiltrosRapidos) {
          this.editandoFiltro = true;
          this.novoFiltro = true;
          let filtro = this.objFiltrosRapidos;
          this.nomeFiltro = filtro.tc010_ds_filtro;
          this.iconeFiltro = filtro.tc010_im_filtro;
          this.filtroAtivo = filtro.tc010_cd_situac === 1 ? true : false;
        }
      },
    },
    sugestaoSelecionada(novaSugestao) {
      const sugestao = this.filtrosSugeridos.find(
        (filtro) => filtro.tc010_ds_filtro === novaSugestao,
      );
      this.nomeFiltro = sugestao.tc010_ds_filtro;
      this.iconeFiltro = sugestao.tc010_im_filtro;
    },
    filtros: {
      deep: true,
      handler() {
        var filtros = this.filtros;
        this.vuexSet({ value: filtros, stateName: "tc010" });
      },
    },
  },
  async mounted() {
    await this.carregaFiltros();
  },
  methods: {
    ...mapActions(["vuexSet"]),
    /*     desativarFiltro() {
      let i = this.filtros.findIndex(filtro => filtro.tc010_nr_filtro === this.objFiltrosRapidos.tc010_nr_filtro)
      this.filtros[i].tc010_cd_situac = 2
      const filtrosAtivos = this.filtros.filter((filtro) => filtro.tc010_cd_situac === 1)
      this.vuexSet({ value: filtrosAtivos, stateName: 'arrayFiltrosRapidos' })
      this.filtroAtivo = false
    }, */
    atualizaStatusFiltro(filtro) {
      filtro.tc010_cd_situac = filtro.active ? 1 : 2;
    },
    editarFiltro(filtro) {
      this.filtroSelecionado = filtro;
      this.editandoFiltro = true;
      this.nomeFiltro = filtro.tc010_ds_filtro;
      this.iconeFiltro = filtro.tc010_im_filtro;
    },
    async salvarFiltros() {
      try {
        this.loadingConfig = true;
        let respostaConfig = await this.request({
          metodo: "post",
          sp: "SP_CE360900010",
          params: { filtrosRapidos: this.filtros },
        });
        console.log("resposta", respostaConfig);
        /* if(respostaConfig) {
          respostaConfig = respostaConfig.database[0]
          this.logoImage = respostaConfig.tc000_im_logo ? `data:image/png;base64,${ respostaConfig.tc000_im_logo }` : null
          this.corPrimaria = respostaConfig.tc000_cd_corpri || null
        } */
      } finally {
        this.loadingConfig = false;
      }
    },
    desativarEdicao() {
      this.nomeFiltro = "";
      this.iconeFiltro = "thumb_up";
      this.editandoFiltro = false;
    },
    ativarFiltro() {
      let i = this.filtros.findIndex(
        (filtro) =>
          filtro.tc010_nr_filtro === this.objFiltrosRapidos.tc010_nr_filtro,
      );
      this.filtros[i].tc010_cd_situac = 1;
      const filtrosAtivos = this.filtros.filter(
        (filtro) => filtro.tc010_cd_situac === 1,
      );
      this.vuexSet({ value: filtrosAtivos, stateName: "arrayFiltrosRapidos" });
      this.filtroAtivo = true;
    },
    async carregaFiltros() {
      try {
        this.loadingConfig = true;
        const retorno = await this.request({
          metodo: "get",
          sp: "SP_CE360900009",
          params: [{}],
        });
        if (retorno.database.length > 0) {
          this.filtros = retorno.database.map((filtro) => {
            return {
              ...filtro,
              tc010_im_filtro: atob(filtro.tc010_im_filtro),
              active: filtro.tc010_cd_situac === 1 ? true : false,
            };
          });

          const filtrosAtivos = this.filtros.filter(
            (filtro) => filtro.tc010_cd_situac === 1,
          );
          this.vuexSet({
            value: filtrosAtivos,
            stateName: "arrayFiltrosRapidos",
          });
        }
      } catch {
        this.notification().error({erro: "Erro ao carregar filtros"});
      } finally {
        this.loadingConfig = false;
      }
    },
    criarNovoFiltro() {
      this.vuexSet({ value: null, stateName: "objFiltrosRapidos" });
      this.nomeFiltro = this.$t("ConfigTelas.nomeFiltro");
      this.iconeFiltro = "thumb_up";
      this.filtroAtivo = true;
      this.bb = [
        { padding: "15px 0" },
        { borderBottom: "5px solid #f49636 !important" },
      ];
      this.editandoFiltro = false;
      this.novoFiltro = true;
    },
    async adicionarFiltro() {
      this.editandoFiltro = false;
      const novoFiltro = {
        tc010_ds_filtro:
          this.nomeFiltro.substr(0, 1).toUpperCase() +
          this.nomeFiltro.substr(1, this.nomeFiltro.length).toLowerCase(),
        tc010_im_filtro: this.iconeFiltro,
        tc010_cd_situac: 1,
        active: true,
      };
      this.sugestaoSelecionada = "";

      this.filtros.push(novoFiltro);
      this.vuexSet({ value: this.filtros, stateName: "arrayFiltrosRapidos" });
    },
    /* async adicionarFiltro() {
      const usuario = await this.$wso2.getUserData();
      this.editandoFiltro = false;
      let req = [
        {
          _rTC010_DS_FILTRO: this.nomeFiltro.substr(0, 1).toUpperCase() + this.nomeFiltro.substr(1, this.nomeFiltro.length).toLowerCase(),
          _rTC010_IM_FILTRO: this.iconeFiltro,
          _rTC010_CD_OPESIS: usuario.base.us01_nr_usr,
        },
      ];
      let retorno = await this.chamaSP("SP_CE360900010", req);
      retorno = retorno.database.map((r) => {
        return {
          ...r,
          tc010_im_filtro: atob(r.tc010_im_filtro),
        };
      });
      this.sugestaoSelecionada = "";
      this.novoFiltro = false;

      this.filtros.push(retorno[0]);
      this.vuexSet({ value: this.filtros, stateName: "arrayFiltrosRapidos" });
    }, */
    alterarFiltro() {
      this.filtroSelecionado.tc010_ds_filtro =
        this.nomeFiltro.substr(0, 1).toUpperCase() +
        this.nomeFiltro.substr(1, this.nomeFiltro.length).toLowerCase();
      this.filtroSelecionado.tc010_im_filtro = this.iconeFiltro;
      this.editandoFiltro = false;
    },
    /* alterarFiltro() {
      let i = this.filtros.findIndex(
        (filtro) =>
          filtro.tc010_nr_filtro === this.objFiltrosRapidos.tc010_nr_filtro,
      );
      this.sugestaoSelecionada = "";
      if (this.filtros[i]) {
        this.filtros[i].tc010_ds_filtro = this.nomeFiltro;
        this.filtros[i].tc010_im_filtro = this.iconeFiltro;
        this.vuexSet({ value: this.filtros, stateName: "arrayFiltrosRapidos" });
        this.atualizaFiltro(
          this.filtros[i].tc010_ds_filtro,
          this.filtros[i].tc010_im_filtro,
          this.filtros[i].tc010_nr_filtro,
          this.filtros[i].tc010_cd_situac,
        );
      }
    }, */
    removerFiltro() {
      let i = this.filtros.findIndex(
        (filtro) =>
          filtro.tc010_nr_filtro === this.objFiltrosRapidos.tc010_nr_filtro,
      );
      this.sugestaoSelecionada = "";
      this.filtros[i].tc010_cd_situac = 2;
      this.vuexSet({ value: this.filtros, stateName: "arrayFiltrosRapidos" });
      this.atualizaFiltro(
        this.filtros[i].tc010_ds_filtro,
        this.filtros[i].tc010_im_filtro,
        this.filtros[i].tc010_nr_filtro,
        this.filtros[i].tc010_cd_situac,
      );
      this.novoFiltro = false;
    },
    async atualizaFiltro(nome, icone, numero, situacao) {
      const req = {
        _rTC010_IM_FILTRO: icone,
        _rTC010_DS_FILTRO: nome,
        _rTC010_NR_FILTRO: numero,
        _rTC010_CD_SITUAC: situacao,
      };

      try {
        await this.request({
          metodo: "get",
          sp: "SP_CE360900003",
          params: req,
        });
      } catch {
        // Tratamento de erro
        this.notification().error({erro: "Erro ao atualizar/deletar o filtro"});
      }
    },
  },
};
</script>

<style>
.switchBoxes {
  max-height: 40vh;
  overflow-y: scroll;
}
</style>